import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import Header from "./components/Header";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import upiqr from "../pages/upi_logo.png";
import "./sidebar.css";
import { useHistory } from 'react-router-dom'; // For navigation

import {
  N_crypto_withdraw,
  N_crypto_withdraw_Otp,
  N_DepositUpdate,
  N_inr_withdraw,
  N_transectionHistory,
  N_ScreenShot,
  N_crypto_withdraw_Otp_Email,
  postAPICall,
  getAPICall,
  N_TransferP2P,
  N_createAddress,
} from "./redux/helpers/api_functions_new";
import Switch from "react-switch";
import { AiFillBank } from "react-icons/ai";
import { getCoinRate } from "./redux/helpers/helper_functions";
import { getUserBalance } from "./redux/actions/coinDBAction";
import { user_logout } from "./redux/actions/authActions";

export default function Wallet(props) {
  const dispatch = useDispatch();
  const [hide_zero, setHideZero] = useState(false);
  const [copied, setCopied] = useState();
  const [volume, setVolume] = useState();
  const [to_address, setToAddress] = useState();
  const [remark, setRemark] = useState();
  const [req_no, setReqno] = useState();
  const [get_token, setGetToken] = useState([]);
  const [showbalance, setShowbalnce] = useState(true);
  // const [withdral_fee, setWthdrawalFee] = useState();
  const [famount, setFinalAmount] = useState(45);
  const [transfersymbol, setTransferSymbol] = useState();
  const [transferType, setTransferType] = useState();
  const [transferAmount, setTransferAmount] = useState();
  const [wallet_details, setWalletDetails] = useState([]);
  const [history, setHistory] = useState("");
  // const [collapseClass, setcollapseClass] = useState(false);
  const [activeTab, setActivetab] = useState(0);
  const [activetype, setActivetype] = useState(1);
  const [deposittab, setDeposittab] = useState(0);
  const [total_inr, setTotalInr] = useState(0);
  const [loading, setLoading] = useState(true);
  const [withLoading, setWithLoading] = useState(false);
  const [price, setPrice] = useState("");
  const [bankverify, setBankverification] = useState(false);
  // const [suser, setUser] = useState();
  const [status, setStatus] = useState(false);
  const [popup, showpopup] = useState(false);
  const [ctime, setctime] = useState("02:00");
  const [wallettype, setwallettype] = useState("");
  const [transection_id, setTransectionId] = useState("");
  const [otp, setOtp] = useState("");
  const [wotp, setwOtp] = useState("");
  const [otpv, setotpv] = useState(false);
  const [withdrawStatus, setWithdrawStatus] = useState(false);
  const [withdrawmsg, setWithdrawmsg] = useState("");
  const [filedata, setFileData] = useState();
  const [l1, setl1] = useState("");
  const [l2, setl2] = useState("");

  //qr
  const { user } = useSelector((state) => state.AuthReducer);
  let token = user?.params ? user.params.token : user.token;

  const [amount, setAmount] = useState('');
  const [showMinDepositMessage, setShowMinDepositMessage] = useState(false);
  const handleClick = () => {
    if (!amount || isNaN(amount)) {
      alert('Please enter a valid amount');
      return;
    }
    const numericAmount = parseFloat(amount);
    if (numericAmount < 500) {
      setShowMinDepositMessage(true);
      
    } else {
      setShowMinDepositMessage(false);
      const targetUrl = `https://rubyshoping.online/pay/${amount}/${token}`;

      window.open(targetUrl, '_blank');
    }
 
  };

  const [qrcode_url, setQRCodeURL] = useState("");
  const [upi_url, setUpiURL] = useState("");

  const { webData } = useSelector((state) => state.websiteDBReducer);
  const { coins, wallet, wallet_loading } = useSelector(
    (state) => state.coinDBReducer
  );
  const { user_fav_currency, currency_prefix, paired_curency_price } =
    useSelector((state) => state.coinDBReducer);

  // function getCoinRate(coin) {
  //   let coins1 = Object.values(coins);
  //   // console.log("coins: ", coins);
  //   let res = coins1.find((d) => d.symbol === coin.toUpperCase());
  //   // console.log("inr price: ", res, coin);
  //   if (coin === "INR") {
  //     return 1;
  //   } else {
  //     return res?.current_price_inr ? res.current_price_inr : 0;
  //   }
  // }

  const uploadIMG = (input) => {
    if (input.target.files && input.target.files[0]) {
      console.log("fileset****");
      setFileData(input.target.files[0]);
    }
  };

  function getWallets() {
    let total = 0;
    let final_data = Object.keys(wallet).map((res, i) => {
      let wallet_type = wallet[res]?.symbol.toUpperCase();
      let rate = getCoinRate(coins, wallet_type);
      let get_sup_currency = null;

      if (get_token) {
        let sp = get_token.filter((gets) => gets.symbol === wallet_type);
        get_sup_currency = sp[0];
      }

      if (isNaN(rate)) {
        rate = 1;
      }

      let inr_val =
        rate *
        (Math.round(wallet[res]?.balance * 10000) / 10000 != 0
          ? Math.round(wallet[res]?.balance * 10000) / 10000
          : Math.round(wallet[res]?.balance * 100000000) / 100000000);
      total = total + parseFloat(inr_val);
      // console.log("inrvaljust: ", inr_val, Math.round(rate * wallet[res]?.balance * 1000) / 1000, rate);
      return {
        id: wallet[res]?.id,
        deposit_limit: wallet[res]?.deposit_limit
          ? wallet[res].deposit_limit
          : 0,
        icon: wallet[res]?.icon,
        symbol: wallet_type,
        name: wallet[res]?.name,
        status: wallet[res]?.status,
        withdral_fee: wallet[res]?.withdrawal_fee,
        locked:
          Math.round(wallet[res]?.locked * 10000) / 10000 != 0
            ? Math.round(
                Math.abs(wallet[res]?.locked ? wallet[res]?.locked : 0) * 10000
              ) / 10000
            : Math.round(wallet[res]?.locked * 100000000) / 100000000,
        address: wallet[res]?.wallet_address,
        balance:
          Math.round(wallet[res]?.balance * 10000) / 10000 != 0
            ? Math.round(wallet[res]?.balance * 10000) / 10000
            : Math.round(wallet[res]?.balance * 100000000) / 100000000,
        p2pbalance: wallet[res]?.p2p_balance ? wallet[res]?.p2p_balance : 0,
        avl_balance:
          Math.round(wallet[res]?.available * 10000) / 10000 != 0
            ? Math.round((wallet[res]?.balance - wallet[res]?.locked) * 10000) /
              10000
            : Math.round(
                (wallet[res]?.balance - wallet[res]?.locked) * 100000000
              ) / 100000000,
        inr_balance: inr_val,
        min_deposit: get_sup_currency
          ? get_sup_currency?.min_deposite_limit
          : "",
        min_withdrawal: get_sup_currency
          ? get_sup_currency?.min_withdraw_limit
          : "",
        max_withdrawal: get_sup_currency
          ? get_sup_currency?.max_withdraw_limit
          : "",
      };
    });
    console.log("final_data", final_data);
    setWalletDetails(final_data);
    setTotalInr(total);
    setTimeout(() => setLoading(false), 800);
  }
  useEffect(() => {
    let c_user_id = user?.params?.token ? user.params.token : user.token;
    let alltxtData = {
      admin_user_id: c_user_id,
    };

    postAPICall("gettoken", alltxtData).then((response) => {
      if (response.data) {
        setGetToken(response.data);
      }
    });
  }, []);

  useEffect(() => {
    if (!wallet_loading) {
      getWallets();
    }
  }, [coins, get_token, wallet]);

  // useEffect(() => {
  //   N_transectionHistory(user.token)
  //     .then((dt) => {
  //       // if (status !== -5) setHistory(dt);
  //       if (dt.status === 200) {
  //         setHistory(dt.params.withdraw);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }, [activeTab]);

  const Ncard =
    wallet_details &&
    wallet_details.map((res) => {
      return <option value={res.symbol}>{res.symbol}</option>;
    });

  useEffect(() => {
    let c_token = user?.params?.token ? user.params.token : user.token;
    N_DepositUpdate(c_token);
  }, []);

  // useEffect(() => {
  //   // let c_token = user?.params?.token ? user.params.token : user.token;
  //   // getQrCode(c_token);
  //   getQrCode().then((data) => {
  //     if (data.status === 200) {
  //       let upi_url = data.data.upi_url; //?.website?.news_later
  //       let qrcode_url = data.data.qrcode_url; //?.website?.news_later

  //       console.log("upi_url", upi_url);
  //       if (upi_url) {
  //         setQRCodeURL(qrcode_url);
  //         setUpiURL(upi_url);
  //       }
  //     }
  //   });
  // }, []);

  function changeCollapse(event, txt) {
    setCopied(false);
    $(".deposit_c").removeClass("show").addClass("collapse");
    $(".withdraw_c").removeClass("show").addClass("collapse");
  }

  const otpCountdown = () => {
    let duration = 60 * 5;
    // const display = document.getElementById("#timer");
    let timer = duration,
      minutes,
      seconds;
    const tint = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setctime(minutes + ":" + seconds);

      if (--timer < 0) {
        // timer = duration;
        clearInterval(tint);
      }
    }, 1000);
  };
  useEffect(() => {
    getAPICall("/get_withdraw_status").then((response) => {
      const res = response.data;
      if (res.status == 200) {
        setWithdrawStatus(res.withdrawal_status);
        setWithdrawmsg(res.withdrawal_msg);
      }
    });
  }, []);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '50vh',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#f9f9f9',
      padding: '20px',
    },
  
    input: {
      padding: '10px',
      fontSize: '16px',
      marginBottom: '10px',
      width: '200px',
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
    button: {
      padding: '10px 20px',
      fontSize: '16px',
      backgroundColor: '#4CAF50',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    disclaimer: {
      marginTop: '20px',
      fontSize: '14px',
      color: '#ff0000',
      textAlign: 'center',
    },
    fee: {
      fontSize: '14px',
      color: '#ff9900',
      marginTop: '10px',
    },minDepositMessage: {
      fontSize: '14px',
      color: '#d9534f',
      marginTop: '10px',
    },
  };
  return (
    <>
      {popup ? (
        <>
          <div
            style={{
              position: "fixed",
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              zIndex: 200,
              backgroundColor: "rgba(0,0,0,0.3)",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "20%",
                zIndex: 1000,
                boxShadow: "2px 2px 20px rgba(0,0,0,0.4)",
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                backgroundColor: "#fafafa",
              }}
            >
              <div className="security_header shead-bg"></div>
              <div className="container shead-bg">
                <div className="row">
                  <div className="col-12 col-md-12 col-sm-12">
                      <>
                        <form className="signupform mdfthemetxt" id="otp_form">
                          <div>
                            <h3>Email OTP verification </h3>
                          </div>
                          <div className="form-group">
                            <small id="msg" style={{ fontSize: "15px" }}>
                              Error message
                            </small>
                          </div>
                          <div className="form-group">
                            <div>
                              <input
                                type="text"
                                name="user_otp"
                                className="form-group"
                                id="user_otp"
                                maxLength={6}
                                onChange={(e) => {
                                  setwOtp(e.target.value);
                                  isOtp(e.target.value);
                                }}
                                value={wotp}
                                placeholder="Enter Your OTP"
                              />
                              <i
                                className="fas fa-check-circle"
                                style={{ top: "16px" }}
                              ></i>
                              <i
                                className="fas fa-exclamation-circle"
                                style={{ top: "16px" }}
                              ></i>
                              <small>Error message</small>
                              <div className="resend_btn text-info" id="timer">
                                {ctime}
                              </div>
                            </div>
                          </div>
                          <div className="form-group" id="btns">
                            <button
                              type="button"
                              id="withdrawal_btn"
                              className="sendbtn"
                              onClick={(e) => {
                                e.preventDefault();
                                setl1("spinner-border spinner-border-sm");
                                document.getElementById(
                                  "withdrawal_btn"
                                ).disabled = true;
                                N_crypto_withdraw_Otp_Email(
                                  user?.params ? user.params.token : user.token,
                                  wotp,
                                  transection_id,
                                  wallettype
                                )
                                  .then((res) => {
                                    setl1("");
                                    showpopup(false);
                                    if (res.status === 200) {
                                      NotificationManager.success(res.message);
                                      document.location.reload();
                                    } else {
                                      NotificationManager.error(res.message);
                                      document.location.reload();
                                    }
                                  })
                                  .catch((err) => {
                                    NotificationManager.error(err.message);
                                    document.location.reload();
                                    console.log(err);
                                  });
                              }}
                            >
                              <span className={`${l1}`}></span>
                              Withdraw
                            </button>
                          </div>
                          {loading ? (
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : null}
                        </form>
                      </>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <Header />
      {/* <WalletSidebar {...props}/> */}
      <div>
        <div className="col-lg-12">
          <div className="modified_collapse sidenav_toggle">
            <button
              class="btn text-white border-0 text-left d-block d-lg-none sidebar_toggle collapsed"
              data-bs-toggle="collapse"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              id="sidebarr"
              aria-controls="collapseExample"
            >
              Sidebar
            </button>

            <div class="sidebar collapse" id="collapseExample">
              {/* <a 
              className={activetype == 0 ? "active" : ""}
              onClick={() => setActivetype(0)}>Overview</a> */}
              <a
                className={activetype == 1 ? "active" : ""}
                onClick={() => {
                  setActivetype(1);
                  document
                    .getElementById("sidebarr")
                    .classList.add("collapsed");
                  document
                    .getElementById("collapseExample")
                    .classList.remove("show");
                }}
              >
                Fiat and Spot
              </a>
              <a
                className={activetype == 2 ? "active" : ""}
                onClick={() => {
                  setActivetype(2);
                  document
                    .getElementById("sidebarr")
                    .classList.add("collapsed");
                  document
                    .getElementById("collapseExample")
                    .classList.remove("show");
                }}
              >
                Funding
              </a>
              <a
                className={activetype == 3 ? "active" : ""}
                onClick={() => {
                  setActivetype(3);
                  document
                    .getElementById("sidebarr")
                    .classList.add("collapsed");
                  document
                    .getElementById("collapseExample")
                    .classList.remove("show");
                  N_transectionHistory(user.token)
                    .then((dt) => {
                      // if (status !== -5) setHistory(dt);
                      if (dt.status === 200) {
                        setHistory(dt.params.withdraw);
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }}
              >
                Transaction History
              </a>
            </div>
          </div>
          <div className="main-content">
            {/* <div className="">
              <h4 className="theme-color-text">Funds And Transfer</h4>
              <div className="">
                <div className="align-items-center justify-content-between mb-3 px-15">
                  <button
                    type="button"
                    className={`mdfthemetxt  btn btn-lg mr-2 ${
                      activeTab == 0 ? "btn-primary active" : ""
                    }`}
                    onClick={() => setActivetab(0)}
                  >
                    Funds
                  </button>
                  <button
                    type="button"
                    className={`mdfthemetxt btn btn-lg ${
                      activeTab == 1 ? "btn-primary active" : ""
                    }`}
                    onClick={() => setActivetab(1)}
                  >
                    Transfer History
                  </button>
                </div>
              </div>
            </div> 
             <div className="box-header with-border">
          <h4 className="box-title theme-color-text float-left">Funds And Transfer</h4>
         
        </div> 
           <div className="d-flex align-items-center justify-content-between mt-2 mb-3 px-15">
        <div className="float-right">
            <h5 className="box-title float-left mr-3">Hide zero balance</h5>
            <div className="float-left">
              <Switch
                onChange={(e) => {
                  setHideZero(hide_zero ? false : true);
                }}
                checked={hide_zero}
              />
            </div>
          </div>

          <ul className="box-controls pull-right mr-4">
            <li>
            Estimated Balance:{currency_prefix[user_fav_currency]}{" "}
              <strong className="">
                {user_fav_currency === "INR"
                  ? total_inr.toFixed(5)
                  : (
                      total_inr / paired_curency_price[user_fav_currency]
                    ).toFixed(8)}
              </strong>
            </li>
          </ul>
        </div> */}
            <div className="container-- col-lg-12 px-3 mt-3">
              <div className="">
                <h3 className="mb-3">
                  {activetype == 1
                    ? "Fiat and Spot"
                    : activetype == 2
                    ? "Funding"
                    : activetype == 3
                    ? "Transaction History"
                    : "Overview"}
                </h3>
                {activetype === 2 ? (
                  <div className="col-md-12 mb-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col-3">
                            <label htmlFor="coin" className="text-muted fs-12">
                              Select Coin
                            </label>
                            <select
                              class="buy-sell-form-bg buy-sell-theme form-control"
                              id="coin"
                              onChange={(e) => {
                                setTransferSymbol(e.target.value);
                              }}
                            >
                              <option selected>Please Select</option>
                              {Ncard}
                            </select>
                          </div>
                          <div className="col-3">
                            <label
                              htmlFor="coin_transfer"
                              className="text-muted fs-12"
                            >
                              Select Transfer
                            </label>
                            <select
                              class="buy-sell-form-bg buy-sell-theme form-control"
                              id="coin_transfer"
                              onChange={(e) => {
                                setTransferType(e.target.value);
                              }}
                            >
                              <option selected>Please Select</option>
                              <option value="add">Fiat to Spot</option>
                              <option value="minus">Spot to Fiat</option>
                            </select>
                          </div>
                          <div className="col-3">
                            <label
                              htmlFor="amount"
                              className="text-muted fs-12"
                            >
                              Enter Amount
                            </label>
                            <input
                              type="text"
                              name="amount"
                              className="buy-sell-form-bg buy-sell-theme form-control"
                              id="amount"
                              value={transferAmount}
                              onChange={(e) => {
                                setTransferAmount(e.target.value);
                              }}
                            />
                          </div>
                          <div className="col-3">
                            <button
                              className="btn btn-danger"
                              style={{ marginTop: "18px" }}
                              onClick={() => {
                                if (
                                  transferAmount > 0 &&
                                  transfersymbol &&
                                  transferType
                                ) {
                                  N_TransferP2P(
                                    user?.params
                                      ? user.params.token
                                      : user.token,
                                    transferAmount,
                                    transfersymbol,
                                    transferType
                                  ).then((data) => {
                                    if (data.status === 200) {
                                      NotificationManager.success(data.message);
                                      document.location.reload();
                                    } else {
                                      NotificationManager.error(data.message);
                                    }
                                  });
                                } else {
                                  NotificationManager.error("Fill all data");
                                }
                              }}
                            >
                              Transfer Balance
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              {activetype == 1 ? (
                <>
                  <div className="card mb-2">
                    <div className="card-body">
                      <div>Estimated Balance{"  "}</div>
                      {currency_prefix[user_fav_currency]}{" "}
                      {showbalance ? (
                        <strong className="fs-16">
                          {user_fav_currency === "INR"
                            ? total_inr.toFixed(5)
                            : (
                                total_inr /
                                paired_curency_price[user_fav_currency]
                              ).toFixed(8)}
                        </strong>
                      ) : (
                        "********"
                      )}
                      <button
                        className="btn btn-sm btn-outline-secondary fs-12 ms-2 py-0"
                        onClick={() => {
                          setShowbalnce(!showbalance);
                        }}
                      >
                        <i
                          className={`fa ${
                            showbalance ? "fa-eye" : "fa-eye-slash"
                          }`}
                        ></i>{" "}
                        {/* Show Balance */}
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mb-3">
                    <span className="position-relative pt-1 me-2">
                      Hide zero balance
                    </span>
                    <Switch
                      onChange={(e) => {
                        setHideZero(hide_zero ? false : true);
                      }}
                      checked={hide_zero}
                    />
                  </div>
                </>
              ) : null}

              {/* <div className="">
                <div className="d-flex justify-content-end mb-2">
                
                  <div className="d-flex">
                    <span className="position-relative pt-1 me-2">
                      Hide zero balance
                    </span>
                    <Switch
                      onChange={(e) => {
                        setHideZero(hide_zero ? false : true);
                      }}
                      checked={hide_zero}
                    />
                  </div>
                </div>
              </div> */}
              <div className="card">
                <div className="card-body px-0 py-0">
                  <div className="table-responsive">
                    {activetype === 1 || activetype === 2 ? (
                      <table
                        className="mdfthemetxt table custom_tbl table-border no-margin"
                        style={{
                          overflow: "hidden",
                        }}
                      >
                        <thead>
                          <tr>
                            <th>
                              <h6>Assets</h6>
                            </th>
                            <th className="text-center">
                              <h6> Available Balance</h6>
                            </th>
                            {activetype === 1 ? (
                              <>
                                <th>
                                  <h6>Locked</h6>
                                </th>
                                <th>
                                  <h6>Total</h6>
                                </th>
                                <th>
                                  <h6>{user_fav_currency} Value</h6>
                                </th>
                                <th>
                                  <h6>Action</h6>
                                </th>
                              </>
                            ) : null}
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td colSpan={8}>
                                <FullLoader />
                              </td>
                            </tr>
                          ) : wallet_details ? (
                            wallet_details.map((item, index) => (
                              <>
                                {index === 0
                                  ? wallet_details.map((item, index) => (
                                      <>
                                        {(hide_zero &&
                                          item.balance > 0 &&
                                          item.symbol === "INR") ||
                                        (!hide_zero &&
                                          item.symbol === "INR") ? (
                                          <>
                                            <tr key={index * 6}>
                                              <td>
                                                {item.icon ? (
                                                  <img
                                                    src={item.icon}
                                                    alt={item.symbol}
                                                    width="20"
                                                    height="20"
                                                  />
                                                ) : (
                                                  ""
                                                )}{" "}
                                                {item.name} ({item.symbol})
                                              </td>
                                              {activetype === 2 ? (
                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {item.p2pbalance}
                                                </td>
                                              ) : null}
                                              {activetype == 1 ? (
                                                <>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {item.avl_balance}
                                                  </td>
                                                  <td>{item.locked}</td>
                                                  <td>
                                                    {item.balance.toFixed(4)}
                                                  </td>
                                                  <td>
                                                    {
                                                      currency_prefix[
                                                        user_fav_currency
                                                      ]
                                                    }{" "}
                                                    {item.inr_balance}
                                                  </td>
                                                  <td>
                                                    {parseInt(item.status) ===
                                                      1 ||
                                                    (parseInt(item.status) ===
                                                      3 &&
                                                      item.symbol == "INR") ? (
                                                      // <a href={`https://login.ctpay.cloud/payment?user_id=${user?.params
                                                      // ? user.params.token
                                                      // : user.token}`} target="_blank" className="wh btn btn-sm btn-outline-primary me-2">
                                                      //   Deposit
                                                      // </a>
                                                      <button
                                                        className="text-green border-0 bg-transparent me-2"
                                                        id="deposit"
                                                        data-toggle="collapse"
                                                        data-target={
                                                          "#inr_" + item.symbol
                                                        }
                                                        aria-expanded="false"
                                                        onClick={(e) =>
                                                          changeCollapse(
                                                            e,
                                                            "#inr_" +
                                                              item.symbol
                                                          )
                                                        }
                                                      >
                                                        Deposit
                                                      </button>
                                                    ) : null}

                                                    {parseInt(item.status) ===
                                                      2 ||
                                                    (parseInt(item.status) ===
                                                      3 &&
                                                      item.symbol == "INR") ? (
                                                      <>
                                                        <button
                                                          className="text-red border-0 bg-transparent"
                                                          id="withdraw"
                                                          data-toggle="collapse"
                                                          data-target={
                                                            "#inrw_" +
                                                            item.symbol
                                                          }
                                                          aria-expanded="false"
                                                          onClick={(e) =>
                                                            changeCollapse(
                                                              e,
                                                              "#inrw_" +
                                                                item.symbol
                                                            )
                                                          }
                                                        >
                                                          Withdraw
                                                        </button>
                                                      </>
                                                    ) : null}
                                                  </td>
                                                </>
                                              ) : null}
                                            </tr>
                                            <tr
                                              className="collapse deposit_c"
                                              id={"inr_" + item.symbol}
                                            >
                                              <td colSpan="6">
                                                <div className="row">
                                                  <div
                                                    className="col-md-6"
                                                    style={{ margin: "0 auto" }}
                                                  >
                                                   <div className="card buy-sell-form-bg buy-sell-theme">
                                                      <div className="card-header">
                                                        Deposit
                                                      </div>
                                                      <div className="card-body">
                                                      <div style={styles.container}>
                                                        <input
                                                          type="text"
                                                          value={amount}
                                                          onChange={(e) => setAmount(e.target.value)}
                                                          placeholder="Enter amount"
                                                          style={styles.input}
                                                        />
                                                        <button onClick={handleClick} style={styles.button}>Submit</button>
                                                        {showMinDepositMessage && (
        <p style={styles.minDepositMessage}>
           The minimum deposit amount is 500 INR.
        </p>
      )}
                                                        <p style={styles.disclaimer}>
                                                          Disclaimer: Please deposit a minimum of 500 INR to this address. If you deposit any other QR or below the minimum limit, it will be lost forever.
                                                        </p>
                                                        <p style={styles.fee}>
        Note: A deposit fee of 50 INR will be applied.
      </p>
                                                      </div>
                                                      </div>

                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr
                                              className="collapse withdraw_c"
                                              id={"inrw_" + item.symbol}
                                            >
                                              <td colSpan="6">
                                                <div className="row">
                                                  <div
                                                    className="col-md-8"
                                                    style={{ margin: "0 auto" }}
                                                  >
                                                    {withdrawStatus ? (
                                                      <div className="card buy-sell-form-bg buy-sell-theme">
                                                        <div className="card-header">
                                                          Withdraw
                                                        </div>
                                                        <div className="card-body">
                                                          <h5 className="card-title text-danger ">
                                                            {withdrawmsg}
                                                          </h5>
                                                          <img
                                                            style={{
                                                              paddingTop:
                                                                "20px",
                                                            }}
                                                            src="https://adminapi.ctapi.live/images/withdraw_logo.png"
                                                            alt=""
                                                          />
                                                        </div>
                                                      </div>
                                                    ) : bankverify ? (
                                                      <div className="card buy-sell-form-bg buy-sell-theme">
                                                        <div className="card-header">
                                                          Urgent: Verify Bank
                                                          Details for Fund
                                                          Safety
                                                        </div>
                                                        <div className="card-body">
                                                          <h5 className="card-title text-danger ">
                                                            Dear valued user,
                                                            <br /> <br />
                                                            To ensure the safety
                                                            of your funds,
                                                            Please update your
                                                            bank account details
                                                            on our website.{" "}
                                                            <br />
                                                            Visit your profile,
                                                            verify and edit as
                                                            needed. your
                                                            security is our
                                                            priority.
                                                            <br />
                                                            Thank you for your
                                                            cooperation.
                                                            <br />
                                                            Account & Cyber team
                                                            ctskola.io
                                                          </h5>
                                                          <a
                                                            href="/payment_option"
                                                            className="btn btn-theme-color border btn-primary"
                                                          >
                                                            Bank Verify
                                                          </a>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <div className="card buy-sell-form-bg buy-sell-theme">
                                                        <div className="card-header">
                                                          Withdraw
                                                        </div>
                                                        <div className="card-body">
                                                          <h5 className="card-title">
                                                            Amount
                                                          </h5>
                                                          <input
                                                            type="text"
                                                            className="form-control buy-sell-form-bg buy-sell-theme"
                                                            required
                                                            placeholder="Enter Amount"
                                                            id="price"
                                                            value={price}
                                                            onChange={(e) => {
                                                              setPrice(
                                                                e.target.value
                                                              );
                                                            }}
                                                          />
                                                          <h5 className="card-title">
                                                            Remark
                                                          </h5>
                                                          <input
                                                            type="text"
                                                            className="form-control input_button buy-sell-form-bg buy-sell-theme"
                                                            required
                                                            id="remark"
                                                            placeholder="Enter Remark"
                                                            value={remark}
                                                            onChange={(e) => {
                                                              setRemark(
                                                                e.target.value
                                                              );
                                                            }}
                                                          />
                                                          <br />
                                                          <a
                                                            className="btn btn-theme-color border"
                                                            onClick={() => {
                                                              setWithLoading(
                                                                true
                                                              );
                                                              N_inr_withdraw(
                                                                user?.params
                                                                  ? user.params
                                                                      .token
                                                                  : user.token,
                                                                price,
                                                                item.symbol,
                                                                remark
                                                              ).then((d) => {
                                                                if (
                                                                  d.message ==
                                                                  "Please varifie your bank first"
                                                                ) {
                                                                  setBankverification(
                                                                    true
                                                                  );
                                                                }
                                                                setWithLoading(
                                                                  false
                                                                );
                                                                if (
                                                                  d.status ==
                                                                  200
                                                                ) {
                                                                  showpopup(
                                                                    true
                                                                  );
                                                                  // setotpv(true);
                                                                  otpCountdown();

                                                                  setwallettype(
                                                                    d.params
                                                                      .symbol
                                                                  );
                                                                  setTransectionId(
                                                                    d.params
                                                                      .transection_id
                                                                  );
                                                                  NotificationManager.success(
                                                                    d.message
                                                                  );
                                                                  setStatus(
                                                                    !status
                                                                  );
                                                                } else if (
                                                                  d.status ===
                                                                  401
                                                                ) {
                                                                  dispatch(user_logout(() => {
                                                                    props.history.push("/login");
                                                                  }));
                                                                  
                                                                } else if (
                                                                  d.status !=
                                                                  300
                                                                ) {
                                                                  NotificationManager.error(
                                                                    d.message
                                                                  );
                                                                  setStatus(
                                                                    !status
                                                                  );
                                                                }
                                                              });
                                                            }}
                                                          >
                                                            {withLoading ? (
                                                              <span className="loading-icon fas fa-spinner fa-spin mr-2"></span>
                                                            ) : (
                                                              "Withdraw"
                                                            )}
                                                          </a>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          </>
                                        ) : null}
                                      </>
                                    ))
                                  : null}
                                {(hide_zero &&
                                  item.balance > 0 &&
                                  item.symbol !== "INR") ||
                                (!hide_zero && item.symbol !== "INR") ? (
                                  <>
                                    <tr key={index * 3}>
                                      <td>
                                        {item.icon ? (
                                          <img
                                            src={item.icon}
                                            alt={item.symbol}
                                            width="20"
                                            height="20"
                                          />
                                        ) : (
                                          ""
                                        )}{" "}
                                        {item.name}
                                        {item.symbol === "USDT"
                                          ? "(" + item.symbol + ")" + " (TRC20)"
                                          : item.symbol === "BUSD"
                                          ? "(" + item.symbol + ")" + " (BEP20)"
                                          : "(" + item.symbol + ")"}
                                      </td>
                                      {activetype === 2 ? (
                                        <td style={{ textAlign: "center" }}>
                                          {item.p2pbalance}
                                        </td>
                                      ) : null}
                                      {activetype === 1 ? (
                                        <>
                                          <td style={{ textAlign: "center" }}>
                                            {item.balance > 0
                                              ? item.avl_balance.toFixed(4)
                                              : item.balance}
                                          </td>
                                          <td>
                                            {item.locked > 0
                                              ? item.locked.toFixed(4)
                                              : item.locked}
                                          </td>
                                          <td>
                                            {item.avl_balance > 0
                                              ? (
                                                  Number(item.avl_balance) +
                                                  Number(item.locked)
                                                ).toFixed(4)
                                              : item.avl_balance}
                                          </td>
                                          <td>
                                            {currency_prefix[
                                              user_fav_currency
                                            ] + " "}
                                            {user_fav_currency === "INR"
                                              ? item.inr_balance
                                              : item.inr_balance > 0
                                              ? (
                                                  item.inr_balance /
                                                  Number(
                                                    paired_curency_price[
                                                      user_fav_currency
                                                    ]
                                                  )
                                                ).toFixed(8)
                                              : 0}
                                          </td>
                                          <td>
                                            {(item.address &&
                                              parseInt(item.status) === 1) ||
                                            parseInt(item.status) === 3 ? (
                                              <button
                                                type="button"
                                                className="border-0 bg-transparent text-green me-2"
                                                id="deposit"
                                                data-toggle="collapse"
                                                // data-target={"#inrw_" + item.symbol}
                                                // aria-expanded="false"
                                                // onClick={(e) =>
                                                //   changeCollapse(e, "#inrw_" + item.symbol)
                                                // }
                                                data-target={
                                                  "#deposit_" + item.symbol
                                                }
                                                aria-expanded="false"
                                                onClick={(e) =>
                                                  changeCollapse(
                                                    e,
                                                    "#deposit_" + item.symbol
                                                  )
                                                }
                                              >
                                                Deposit
                                              </button>
                                            ) : null}
                                            {(item.address &&
                                              parseInt(item.status) === 2) ||
                                            parseInt(item.status) === 3 ? (
                                              <button
                                                type="button"
                                                className="border-0 bg-transparent text-red  me-2"
                                                id="withdraw"
                                                data-toggle="collapse"
                                                data-target={
                                                  "#withdraw_" + item.symbol
                                                }
                                                aria-expanded="false"
                                                onClick={(e) =>
                                                  changeCollapse(
                                                    e,
                                                    "#withdraw_" + item.symbol
                                                  )
                                                }
                                              >
                                                Withdraw
                                              </button>
                                            ) : null}
                                            <button
                                              type="button"
                                              className="border-0 bg-transparent text-primary me-2"
                                              id="Trade"
                                              onClick={() => {
                                                localStorage.setItem(
                                                  "precoinpair",
                                                  `/exchange/${item.symbol.toLowerCase()}-${"INR".toLowerCase()}`
                                                );
                                                props.history.push(
                                                  `/exchange/${item.symbol.toLowerCase()}-${"INR".toLowerCase()}`
                                                );
                                              }}
                                            >
                                              Trade
                                            </button>
                                            {!item.address &&
                                            parseInt(item.status) == 1 ? (
                                              <button
                                                type="button"
                                                className="badge border-0 fs-10"
                                                id="create"
                                                onClick={() => {
                                                  document.getElementById(
                                                    "create"
                                                  ).style.display = "none";
                                                  N_createAddress(
                                                    user?.params
                                                      ? user.params.token
                                                      : user.token,
                                                    item.symbol
                                                  ).then((data) => {
                                                    if (data.status == 200) {
                                                      NotificationManager.success(
                                                        data.message
                                                      );
                                                      dispatch(
                                                        getUserBalance(
                                                          user?.params
                                                            ? user.params.token
                                                            : user.token
                                                        )
                                                      );
                                                    } else {
                                                      NotificationManager.success(
                                                        data.message
                                                      );
                                                    }
                                                  });
                                                }}
                                              >
                                                + Create
                                              </button>
                                            ) : null}
                                          </td>
                                        </>
                                      ) : null}
                                    </tr>
                                    <tr
                                      className="collapse deposit_c"
                                      id={"deposit_" + item.symbol}
                                    >
                                      <td colSpan="6">
                                        <form>
                                          <div className="row">
                                            <div className="col-md-6">
                                              <div className="form-group">
                                                <div>
                                                  Send to your Secure{" "}
                                                  {item.name} Address
                                                </div>
                                                <hr className="h_r" />
                                              </div>
                                              <div className="form-group">
                                                <label htmlFor="coin_address">
                                                  Destination Address
                                                </label>
                                                <CopyToClipboard
                                                  text={item.address}
                                                  onCopy={() =>
                                                    setCopied({ copied: true })
                                                  }
                                                >
                                                  {copied ? (
                                                    <span className="cop_btn text-success">
                                                      Copied
                                                    </span>
                                                  ) : (
                                                    <span className="cop_btn theme-color-text">
                                                      <i
                                                        className="fas fa-copy"
                                                        aria-hidden="true"
                                                      ></i>
                                                      Copy
                                                    </span>
                                                  )}
                                                </CopyToClipboard>
                                                <input
                                                  type="text"
                                                  className="input_button buy-sell-form-bg buy-sell-theme"
                                                  value={item.address}
                                                />
                                              </div>
                                              <div className="form-group ">
                                                <div className=" text-warning">
                                                  <i
                                                    className="fas fa-warning"
                                                    aria-hidden="true"
                                                  ></i>{" "}
                                                  Disclaimer
                                                </div>
                                                <hr className="h_r" />
                                                <label htmlFor="disclaimer">
                                                  Please Deposit{" "}
                                                  {item.min_deposit
                                                    ? " minimum " +
                                                      item.min_deposit +
                                                      " "
                                                    : " only "}
                                                  {item.symbol} to this address.
                                                  If you Deposit any other coins
                                                  or below minimum limit, It
                                                  will be lost forever.
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="form-group">
                                                <div>Or Scan This QR Code</div>
                                                <hr className="h_r" />
                                              </div>
                                              <div className="form-group">
                                                <div
                                                  className="p-3"
                                                  style={{
                                                    width: "fit-content",
                                                    backgroundColor: "#fff",
                                                  }}
                                                >
                                                  <QRCode
                                                    value={item.address}
                                                    size={200}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </td>
                                    </tr>
                                    <tr
                                      className="collapse withdraw_c"
                                      id={"withdraw_" + item.symbol}
                                    >
                                      <td colSpan="6">
                                        <form>
                                          <div className="row">
                                            <div
                                              className="col-md-8"
                                              style={{ margin: "0 auto" }}
                                            >
                                              <div className="form-group">
                                                <div>
                                                  Transfer {item.name} from your{" "}
                                                  {webData.website_short_name}
                                                  Wallet to Another
                                                </div>
                                                <hr className="h_r" />
                                              </div>
                                              <div className="form-group">
                                                <label htmlFor="coin_address">
                                                  Volume
                                                </label>
                                                <label
                                                  htmlFor="coin_val"
                                                  style={{
                                                    float: "right",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Available {item.symbol}:{" "}
                                                  {item.balance}
                                                </label>
                                                <input
                                                  type="text"
                                                  className="input_button buy-sell-form-bg buy-sell-theme"
                                                  value={item.volume}
                                                  maxLength={15}
                                                  onChange={(e) => {
                                                    setVolume(
                                                      e.target.value
                                                        .replace(/[^0-9.]/g, "")
                                                        .replace(
                                                          /(\..*?)\..*/g,
                                                          "$1"
                                                        )
                                                    );
                                                    setFinalAmount(
                                                      e.target.value
                                                        .replace(/[^0-9.]/g, "")
                                                        .replace(
                                                          /(\..*?)\..*/g,
                                                          "$1"
                                                        ) - item.withdral_fee
                                                    );
                                                  }}
                                                />
                                              </div>
                                              <div className="form-group">
                                                <label htmlFor="coin_address">
                                                  Destination Address
                                                </label>
                                                <input
                                                  type="text"
                                                  className="input_button buy-sell-form-bg buy-sell-theme"
                                                  maxLength={60}
                                                  onChange={(e) =>
                                                    setToAddress(e.target.value)
                                                  }
                                                />
                                              </div>
                                              {/* <div className="form-group">
                                            <label htmlFor="coin_address">
                                              Withdrawal Fee
                                            </label>
                                            <input
                                              type="text"
                                              className="input_button"
                                              value={item.withdral_fee}
                                              readOnly
                                            />
                                          </div>
                                          <div className="form-group">
                                            <label htmlFor="coin_address">
                                              Final Valume
                                            </label>
                                            <input
                                              type="text"
                                              className="input_button"
                                              value={famount >= 0 ? famount : "0"}
                                              maxLength={15}
                                            />
                                          </div> */}
                                              <div className="form-group">
                                                <label htmlFor="coin_address">
                                                  Remark
                                                </label>
                                                <input
                                                  type="text"
                                                  className="input_button buy-sell-form-bg buy-sell-theme"
                                                  value={remark}
                                                  maxLength={60}
                                                  onChange={(e) =>
                                                    setRemark(e.target.value)
                                                  }
                                                />
                                              </div>
                                              <div className="form-group">
                                                <button
                                                  type="button"
                                                  className="btn btn-danger"
                                                  onClick={() => {
                                                    setWithLoading(true);

                                                    N_crypto_withdraw(
                                                      user?.params
                                                        ? user.params.token
                                                        : user.token,
                                                      item.symbol,
                                                      item.address,
                                                      to_address,
                                                      volume,
                                                      remark
                                                    ).then((d) => {
                                                      setWithLoading(false);
                                                      if (d.status == 200) {
                                                        showpopup(true);
                                                        // setotpv(true);
                                                        otpCountdown();
                                                        setwallettype(
                                                          d.params.symbol
                                                        );
                                                        setTransectionId(
                                                          d.params
                                                            .transection_id
                                                        );
                                                        console.log("wr2: ", d);
                                                        setStatus(!status);
                                                        NotificationManager.success(
                                                          d.message
                                                        );
                                                      } else if (
                                                        d.status === 401
                                                      ) {
                                                     
                                                        dispatch(user_logout(() => {
                                                          props.history.push("/login");
                                                        }));
                                                        
                                                      } else if (
                                                        d.status != 300
                                                      ) {
                                                        NotificationManager.error(
                                                          d.message
                                                        );
                                                      }
                                                    });
                                                  }}
                                                >
                                                  {withLoading ? (
                                                    <span className="loading-icon fas fa-spinner fa-spin mr-2"></span>
                                                  ) : (
                                                    "PROCEED WITH WITHDRAWAL"
                                                  )}
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </td>
                                    </tr>
                                  </>
                                ) : null}
                              </>
                            ))
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                    ) : null}
                    {activetype === 3 ? (
                      <table className="table custom_tbl">
                        <thead>
                          <tr className="">
                            <th>
                              <h6>Assets</h6>
                            </th>
                            <th>
                              <h6>Type</h6>
                            </th>
                            <th>
                              <h6>Volume</h6>
                            </th>
                            <th>
                              <h6>Status/ Auditor</h6>
                            </th>
                            <th className="">
                              <h6>Address</h6>
                            </th>
                            <th>
                              <h6>Hash Or Txn ID </h6>
                            </th>
                            <th>
                              <h6>Time</h6>
                            </th>
                          </tr>
                        </thead>
                        {history ? (
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan={8}>
                                  <FullLoader />
                                </td>
                              </tr>
                            ) : history ? (
                              history.map((d, index) => (
                                <tr key={index * 5}>
                                  <td>{d.symbol}</td>
                                  <td className="text-capitalize">{d.type}</td>
                                  <td>{d.amount}</td>
                                  <td className="text-capitalize">
                                    <span
                                      className={`badge bg-light ${
                                        d.status == 1
                                          ? "text-success"
                                          : d.status == -2
                                          ? "text-danger"
                                            ? "text-danger"
                                            : "text-warning"
                                          : "text-info"
                                      } fs-14`}
                                    >
                                      {d.status == 1
                                        ? "Success"
                                        : d.status == -2
                                        ? d.auditor_msg
                                          ? d.auditor_msg
                                          : "cancel"
                                        : "Pending"}
                                    </span>
                                  </td>
                                  <td>
                                    {d.to_address ? (
                                      <>
                                        <div className="list-inline-item text-truncate width120">
                                          {d.to_address}
                                        </div>
                                        <CopyToClipboard
                                          text={d.to_address}
                                          onCopy={() =>
                                            NotificationManager.info("Copied!")
                                          }
                                        >
                                          <a
                                            className="text-muted"
                                            type="button"
                                          >
                                            <i className="fa fa-copy fs-5"></i>
                                          </a>
                                        </CopyToClipboard>
                                      </>
                                    ) : (
                                      <div className="list-inline-item text-truncate width120">
                                        N/A
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <div
                                      className="list-inline-item"
                                      style={{
                                        width: "200px",
                                      }}
                                    >
                                      {d.tx_id
                                        ? d.tx_id.substr(0, 20) + "..."
                                        : ""}
                                    </div>
                                    <CopyToClipboard
                                      text={d.tx_id}
                                      onCopy={() =>
                                        NotificationManager.info("Copied!")
                                      }
                                    >
                                      <a className="text-muted" type="button">
                                        <i className="fa fa-copy fs-5"></i>
                                      </a>
                                    </CopyToClipboard>
                                  </td>
                                  <td>
                                    {new Date(d.createdAt).toLocaleString()}
                                  </td>
                                </tr>
                              ))
                            ) : null}
                          </tbody>
                        ) : null}
                      </table>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
